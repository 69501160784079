import LoginLayout from "@/layouts/LayoutContainers/LoginLayout";
import nhlogo from "assets/images/avs-logo.png";
import pattern from "assets/images/shapes/pattern-lines.svg";
import { Form, Formik } from "formik";
import { GetServerSidePropsContext } from "next";
import { getSession, signIn } from "next-auth/react";
import { useRouter } from "next/router";

import FormFieldError from "@/components/FormFieldError";
import SoftBox from "@/components/SoftBox";
import SoftLoadingButton from "@/components/SoftLoadingButton";
import { useTranslations } from "next-intl";
import { useSnackbar } from "notistack";

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslations("login");
  const router = useRouter();

  async function onSubmit(values: any) {
    const status = await signIn("credentials", {
      redirect: false,
      username: values.username,
      password: values.password,
      callbackUrl: (router.query.callbackUrl as string) || "/",
    });

    if (status?.ok) router.push(status.url!);
    else enqueueSnackbar(t("error"), { color: "error" });
  }

  return (
    <LoginLayout
      title="Sign In"
      description="Enter your username and password to sign in"
      color={"info"}
      illustration={{
        image: nhlogo,
        pattern: pattern,
      }}
    >
      <Formik initialValues={{ username: "", password: "" }} onSubmit={onSubmit}>
        {({ values, handleSubmit, touched, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <SoftBox mb={2}>
              <FormFieldError type="text" name="username" placeholder="Username" size="large" required />
            </SoftBox>
            <SoftBox mb={2}>
              <FormFieldError type="password" name="password" placeholder="Password" size="large" required />
            </SoftBox>
            <SoftBox mt={3} mb={1}>
              <SoftLoadingButton
                type="submit"
                variant="gradient"
                color="info"
                size="large"
                loading={isSubmitting}
                loadingIndicator="Loading..."
                fullWidth
              >
                sign in
              </SoftLoadingButton>
            </SoftBox>
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
}

export async function getServerSideProps({ req, res }: GetServerSidePropsContext) {
  const session = await getSession({ req });

  if (!session) {
    return {
      props: {},
    };
  }
  // authorize user return session
  return {
    redirect: {
      destination: "/",
      premanent: false,
    },
  };
}
