/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// formik components
import { ErrorMessage, Field } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { FC } from "react";

// declaring props types for MDBox
type Props = {
  label?: any;
  name: string;
  [key: string]: any;
}

const FormFieldError: FC<Props> = ({label, name, ...rest}) => {
  return (
    <>
      {label && (
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </SoftTypography>
        </SoftBox>
      )}
      <Field {...rest} name={name} as={SoftInput} />
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage render={msg => <div>{msg}</div>} name={name} />
        </SoftTypography>
      </SoftBox>
    </>
  );
}

FormFieldError.defaultProps = {};

export default FormFieldError;
