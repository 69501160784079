import SoftLoadingButtonRoot from "@/components/SoftLoadingButton/SoftLoadingButtonRoot";
import { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { FC, forwardRef, ReactNode } from "react";

interface Props extends Omit<LoadingButtonProps, "color" | "variant"> {
  color?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "default";
  variant?: "text" | "contained" | "outlined" | "gradient";
  size?: "small" | "medium" | "large";
  circular?: boolean;
  iconOnly?: boolean;
  children?: ReactNode;
  [key: string]: any;
}

const SoftLoadingButton: FC<Props> = forwardRef(({ color, variant, size, circular, iconOnly, ...rest }, ref) => (
  <SoftLoadingButtonRoot
    {...rest}
    ref={ref}
    color="primary"
    variant={variant === "gradient" ? "contained" : variant}
    size={size}
    ownerState={{ color, variant, size, circular, iconOnly }}
  />
));

// Setting default values for the props of SoftLoadingButton
SoftLoadingButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

export default SoftLoadingButton;
SoftLoadingButton.displayName = "SoftLoadingButton";
